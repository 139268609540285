<template>
  <div class="DashboardBalances">
    <div class="row row--align-center row--justify-between mb-17">
      <!-- # Total ($XXX) or "There are no balances" -->
      <div v-if="balances && balances.length">
        <label class="fc-light fs-14">
          {{ balances.length }}
          Total ({{
            Intl
              .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(balanceTotalAmount)
          }})
        </label>
      </div>
      <div v-else>
        <label class="fc-light fs-14">There are no balances</label>
      </div>
      <v-select
        v-model="positivity"
        @input="getClientsBalances"
        aria-label="Select input to choose positivity of client balances"
        class="DashboardBalances__select"
        :clearable="false"
        data-cy="employee-balances-filter"
        :options="positivityOptions"
        :placeholder="'View:'"
      />
    </div>
    <div class="DashboardBalances__table">
      <div class="DashboardBalances__head-wrapper row row--justify-between no-wrap">
        <label class="DashboardBalances__head-lbl">Client</label>
        <label class="DashboardBalances__head-lbl">Amount</label>
        <label class="DashboardBalances__head-lbl">Last 24 Hours</label>
        <label class="DashboardBalances__head-lbl">Zero Date</label>
        <label class="DashboardBalances__head-lbl">Payment Plan</label>
      </div>
      <div
        v-for="(balance, index) in balances"
        :key="index"
        data-cy="employee-balance-row"
      >
        <div class="DashboardBalances__client-wrapper row row--align-center">
          <!-- Client legal name should not be changed from the underwriting/client's input -->
          <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
          <!-- Client Name -->
          <label
            @click="goToClientProfile(balance.clientId)"
            class="DashboardBalances__client-wrapper-column fs-14 clickable"
            data-cy="employee-balance-row-client"
          >
            {{ balance.client }}
          </label>

          <!-- AMOUNT -->
          <label
            :class="['DashboardBalances__client-wrapper-column fs-14', {
              'fc-red': balance.balancePositivity === '-',
              'fc-green': balance.balancePositivity === '+',
            }]"
            :id="`Testing__EmployeeBalancesClientBalanceLabel-${balance.clientId}`"
          >
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((balance.balanceAmount / 100))
            }}

            <!-- {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((changedBalances[balance.clientId] / 100))
            }} -->

          </label>

          <!-- LAST 24 HOURS -->
          <label
            v-if="changedBalances[balance.clientId]"
            :class="['DashboardBalances__client-wrapper-column fs-14', {
              'fc-red': changedBalances[balance.clientId] < 0,
              'fc-green': changedBalances[balance.clientId] > 0
            }]"
          >
            {{
              new Intl
                .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(changedBalances[balance.clientId] / 100)
            }}
          </label>

          <label
            v-if="!changedBalances[balance.clientId]"
            class="DashboardBalances__client-wrapper-column fs-14 fc-light"
          >
            0
          </label>

          <!-- ZERO DATE -->
          <time
            class="DashboardBalances__client-wrapper-column fs-14"
            :datetime="balance.zeroDate | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ balance.zeroDate | datetime('MM/DD/YYYY') }}
          </time>

          <!-- PAYMENT PLAN -->
          <div class="DashboardBalances__client-wrapper-column fs-14">
            {{ balance.paymentPlan }}
          </div>
        </div>
      </div>
    </div>

    <base-pagination
      @paginate="page = $event"
      :item-count="balancesTotal"
      :page="page"
      :request-limit="50"
    />
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
// Helpers
import { Client } from '../../utils/api'
// Components
import BasePagination from '../../components/base-pagination.vue'

export default {
  name: 'DashboardBalances',

  components: {
    BasePagination,
  },

  async created () {
    this.getClientsBalances()
    this.getChangedBalances()
  },

  data () {
    return {
      balances: null,
      balancesTotal: null,
      balanceTotalAmount: null,
      changedBalances: [],
      page: 1,
      positivity: 'negative',
      positivityOptions: ['all', 'positive', 'negative'],
    }
  },

  methods: {
    async getClientsBalances () {
      let clients
      try {
        if (this.positivity !== 'all') {
          clients = (await Client.search({
            filters: this.positivity === 'negative' ? 'balance < 0' : 'balance > 0',
            page: this.page - 1,
          }, 500)).data
        } else {
          // grab negative and positive clients
          const clients_negative = (await Client.search({
            filters: 'balance < 0',
            page: this.page - 1,
          }, 500)).data

          const clients_positive = (await Client.search({
            filters: 'balance > 0',
            page: this.page - 1,
          }, 500)).data

          clients = {
            count: clients_positive.nbHits + clients_negative.nbHits,
            hits: clients_negative.hits.concat(clients_positive.hits)
          }
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Balances "Get Client Balances"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the clients\'s balances' })
      }

      if (!clients) return
      this.balances = clients.hits
        .map(client => {
          return {
            balanceAmount: client.balance,
            balancePositivity: client.nacha_sign,
            client: client.shortened_name,
            clientId: client.id,
            paymentPlan: client.payment_plan_details,
            zeroDate: client.zero_date
          }
        })
        .sort((a, b) => {
          // If positivity is "positive," display most to least
          if (this.positivity === 'positive') return b.balanceAmount - a.balanceAmount
          // If positivity is "all" or "negative," display least to most
          return a.balanceAmount - b.balanceAmount
        })
      this.balanceTotalAmount = this.balances.reduce((a, b) => {
        return a + parseFloat(b.balanceAmount / 100)
      }, 0)
      this.balancesTotal = clients.count
    },

    async getChangedBalances () {
      try {
        this.changedBalances = (await Client.getChangedBalances()).data
      } catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Balances "Get Changed Balances"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the clients\'s balance changes' })
      }
    },

    goToClientProfile (clientId) {
      this.$router.push({
        name: 'client',
        params: {
          id: clientId.toString(),
        },
      })
    }
  }
}
</script>

<style lang="sass">
.DashboardBalances

  &__client-wrapper
    border-top: $border
    padding: rem(17px) rem(27px)

  &__client-wrapper-column
    flex: 1 0 0
    margin-right: 1rem

    &:last-child
      flex: 0 0 rem(300px)
      margin-right: 0

  &__head-lbl
    color: $text-light
    font-size: rem(12px)
    line-height: rem(18px)
    flex: 1 0 0
    margin-right: 1rem
    text-align: left
    text-transform: uppercase

    &:last-child
      flex: 0 0 rem(300px)
      margin-right: 0

  &__head-wrapper
    border-radius: rem(4px) rem(4px) 0 0
    padding: rem(10px) rem(27px)

  &__select
    width: rem(175px)

    .vs__selected
      &::before
        content: 'View:'
        padding-right: rem(4px)

  &__table
    background-color: $white
    border-radius: $border-radius
    box-shadow: $container-shadow
    width: 100%
</style>