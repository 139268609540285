var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DashboardBalances" },
    [
      _c(
        "div",
        { staticClass: "row row--align-center row--justify-between mb-17" },
        [
          _vm.balances && _vm.balances.length
            ? _c("div", [
                _c("label", { staticClass: "fc-light fs-14" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.balances.length) +
                      "\n        Total (" +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.balanceTotalAmount)
                      ) +
                      ")\n      "
                  )
                ])
              ])
            : _c("div", [
                _c("label", { staticClass: "fc-light fs-14" }, [
                  _vm._v("There are no balances")
                ])
              ]),
          _c("v-select", {
            staticClass: "DashboardBalances__select",
            attrs: {
              "aria-label":
                "Select input to choose positivity of client balances",
              clearable: false,
              "data-cy": "employee-balances-filter",
              options: _vm.positivityOptions,
              placeholder: "View:"
            },
            on: { input: _vm.getClientsBalances },
            model: {
              value: _vm.positivity,
              callback: function($$v) {
                _vm.positivity = $$v
              },
              expression: "positivity"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "DashboardBalances__table" },
        [
          _vm._m(0),
          _vm._l(_vm.balances, function(balance, index) {
            return _c(
              "div",
              { key: index, attrs: { "data-cy": "employee-balance-row" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "DashboardBalances__client-wrapper row row--align-center"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "DashboardBalances__client-wrapper-column fs-14 clickable",
                        attrs: { "data-cy": "employee-balance-row-client" },
                        on: {
                          click: function($event) {
                            return _vm.goToClientProfile(balance.clientId)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(balance.client) + "\n        "
                        )
                      ]
                    ),
                    _c(
                      "label",
                      {
                        class: [
                          "DashboardBalances__client-wrapper-column fs-14",
                          {
                            "fc-red": balance.balancePositivity === "-",
                            "fc-green": balance.balancePositivity === "+"
                          }
                        ],
                        attrs: {
                          id:
                            "Testing__EmployeeBalancesClientBalanceLabel-" +
                            balance.clientId
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(balance.balanceAmount / 100)
                            ) +
                            "\n\n          "
                        )
                      ]
                    ),
                    _vm.changedBalances[balance.clientId]
                      ? _c(
                          "label",
                          {
                            class: [
                              "DashboardBalances__client-wrapper-column fs-14",
                              {
                                "fc-red":
                                  _vm.changedBalances[balance.clientId] < 0,
                                "fc-green":
                                  _vm.changedBalances[balance.clientId] > 0
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                  }).format(
                                    _vm.changedBalances[balance.clientId] / 100
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    !_vm.changedBalances[balance.clientId]
                      ? _c(
                          "label",
                          {
                            staticClass:
                              "DashboardBalances__client-wrapper-column fs-14 fc-light"
                          },
                          [_vm._v("\n          0\n        ")]
                        )
                      : _vm._e(),
                    _c(
                      "time",
                      {
                        staticClass:
                          "DashboardBalances__client-wrapper-column fs-14",
                        attrs: {
                          datetime: _vm._f("datetime")(
                            balance.zeroDate,
                            "YYYY-MM-DD hh:mm:ssZ"
                          )
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("datetime")(balance.zeroDate, "MM/DD/YYYY")
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "DashboardBalances__client-wrapper-column fs-14"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(balance.paymentPlan) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          })
        ],
        2
      ),
      _c("base-pagination", {
        attrs: {
          "item-count": _vm.balancesTotal,
          page: _vm.page,
          "request-limit": 50
        },
        on: {
          paginate: function($event) {
            _vm.page = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "DashboardBalances__head-wrapper row row--justify-between no-wrap"
      },
      [
        _c("label", { staticClass: "DashboardBalances__head-lbl" }, [
          _vm._v("Client")
        ]),
        _c("label", { staticClass: "DashboardBalances__head-lbl" }, [
          _vm._v("Amount")
        ]),
        _c("label", { staticClass: "DashboardBalances__head-lbl" }, [
          _vm._v("Last 24 Hours")
        ]),
        _c("label", { staticClass: "DashboardBalances__head-lbl" }, [
          _vm._v("Zero Date")
        ]),
        _c("label", { staticClass: "DashboardBalances__head-lbl" }, [
          _vm._v("Payment Plan")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }